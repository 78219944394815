import 'plyr/dist/plyr.css';

import Plyr from 'plyr';
import { Options, Vue } from 'vue-class-component'

import Application from '/@front/shared/application'

@Options({})
class HomeView extends Vue {
  public mounted() {
    document.querySelectorAll('.player').forEach((player) => {
      new Plyr(player as HTMLElement)
    })
  }
}

export default new Application(HomeView)
